
const anaLabelMap = {
  UPPER_LEFT_LABEL: 'lu_corner_mark_', // 左上
  UPPER_RIGHT_LABEL: 'ru_corner_mark_', // 右上
  LOWER_LEFT_LABEL: 'll_corner_mark_', // 左下
  LOWER_RIGHT_LABEL: 'rl_corner_mark_', // 右下
}
  
export const getLocateLabelsAna = (locateLabels, visibleSet) => {
    
  const arr = Array.from(visibleSet).map(label => {
    const curData = locateLabels[label]
    const isCustom = curData.materialValueKey === 'custom'
    const key = isCustom ? `custom_${curData.subscriptType}` : curData.materialValueKey
    const id = curData.locateLabelId || '-'
  
    return `${anaLabelMap[label]}${key}_${id}`
  })
  return arr.join('|')
}
  
